<template>
  <div>
    <b-card title="Görüntüle">
      <b-table-simple>
        <b-thead>
          <b-tr>
            <b-th width="200">
              No
            </b-th>
            <b-td>{{ orderData.id }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Ödeme Durumu
            </b-th>
            <b-td>{{ orderData.order_status }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>Üye</b-th>
            <b-td>{{ orderData.member }}</b-td>
          </b-tr>
          <b-tr>
            <b-td
              class="text-white text-center pl-0"
              style="background: #FF7032"
              colspan="2"
            >
              Sipariş Detayları
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>Paket</b-th>
            <b-th>Tutar</b-th>
          </b-tr>
          <b-tr
            v-for="(lines, key) in orderData.lines"
            :key="key"
          >
            <b-td>
              {{ lines.package }}
            </b-td>
            <b-td>
              {{ lines.amount | toCurrency }} ₺
            </b-td>
          </b-tr>
        </b-thead>
      </b-table-simple>
    </b-card>
    <b-card title="Sipariş Durumu">
      <b-form-group
        label="Sipariş Durumu"
        label-for="order_status"
      >
        <v-select
          id="order_status"
          v-model="orderData.id_order_statuses"
          label="title"
          :options="orderStatusesData"
          :reduce="orderStatusesData => orderStatusesData.id"
        />
      </b-form-group>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BButton, BTableSimple, BTr, BTd, BTh, BThead, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'View',
  components: {
    BCard,
    BButton,
    BTableSimple,
    BTr,
    BTd,
    BTh,
    BThead,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      submitStatus: false,
      formData: {
        id_order_statuses: null,
      },
    }
  },
  computed: {
    orderData() {
      return this.$store.getters['orders/getOrder']
    },
    orderStatusesData() {
      return this.$store.getters['orderStatuses/getOrderStatuses']
    },
    saveStatus() {
      return this.$store.getters['orders/getOrderSaveStatus']
    },
  },
  watch: {
    orderData(val) {
      this.setFormData(val)
    },
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getData()
    this.getOrderStatuses()
  },
  methods: {
    getData() {
      this.$store.dispatch('orders/orderView', this.$route.params.id)
    },
    getOrderStatuses() {
      this.$store.dispatch('orderStatuses/orderStatusesList')
    },
    setFormData(data) {
      this.formData = data
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('orders/orderSave', this.formData)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
